import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const StyleWrapper = styled.div`
  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  text-decoration: none;

  ${({ theme }) => theme.media.desktop} {
    width: 30%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-right: 10px;

    &:last-child {
      margin-right: 0px;
    }
  }
`;
const StyledDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  
  &:last-child {
    border:none;
  }

  ${({ theme }) => theme.media.desktop} {
    width: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 2px;
    margin-bottom: 30px;
  }
`;

const StyledH2 = styled.h2`
  font-size: ${({ theme }) => theme.font.size.s};
  font-family: ${({ theme }) => theme.font.family.Montserrat};
  color: ${({ theme }) => theme.blackfont};
  font-weight: 700;
`;

const H3 = styled.div`
  color: ${({ isBlue }) => isBlue ? ({ theme }) => theme.blue : ({ theme }) => theme.blackfont};
  font-size: ${({ theme }) => theme.font.size.xxm};
  margin-top: ${({ isTop }) => isTop ? '25px' : '10px'};
  margin-bottom: 10px;
  font-weight: 600;
`;


const StyledP = styled.div`
  width: auto;
  font-size: ${({ theme }) => theme.font.size.xs};
  color: ${({ theme }) => theme.blackfont};
  margin-left: ${({ isLeft }) => isLeft ? '60px' : '2px'};
  padding-top: 2px;


  p {
    a {
      text-decoration: none;
    }
  }
`;

const Img = styled.img`
  width: ${({ isLeft }) => isLeft ? '200px' : '50px'};
  height: ${({ isLeft }) => isLeft ? '280px' : '70px'};
  margin-right: 5px;
  margin-top: 0;
`;
const LinksHead = styled.div`
  width: 100%;
  height: 45px;
  display: flex;
`;
const LinksDown = styled.div`
  width: 100%;
  height: auto;

  ${({ theme }) => theme.media.desktop} {
    width: 22vw;
    min-width: 280px;
    height: 30%;
    padding: 1px;
  }

  ${({ theme }) => theme.media.large} {
    width: auto;
    max-width: 19vw;
    height: auto;
  }
`;
const StyledLinksHead = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
`;
const Icons = styled.div`
  margin-right: 4px;`;

const Quides = ({ category }) => {
  const data = useStaticQuery(graphql`
      query {
          allDatoCmsQuide(sort: {fields: sort}) {
              nodes {
                  id
                  categorysc
                  title
                  hidelinkdown
                  imgfr {
                      filename
                      url
                      customData
                  }
                  linkup {
                      ... on DatoCmsImgup {
                          id
                          imgdata {
                              filename
                              url
                          }
                      }
                      ... on DatoCmsLink {
                          id
                          link
                      }
                  }
                  linkdown {
                      id
                      linkd
                  }
              }
          }
      }
  `);

  return (
    <StyleWrapper>
      <H3 isBlue>{category}</H3>

      {data.allDatoCmsQuide.nodes.filter(Fre => Fre.categorysc === category).map(Fre => (

        <StyledDiv key={Fre.id}>

          <StyledH2>{Fre.title}</StyledH2>

          <LinksHead>
            <Img src={Fre.imgfr.customData.ownUrl} alt={Fre.imgfr.filename} />
            {!Fre.hidelinkdown ?
              (<>
                <StyledLinksHead>

                  {Fre.linkup.map(item => {
                    const itemKey = Object.keys(item)[1];

                    switch (itemKey) {
                      case 'link':
                        return <StyledP key={item.id} dangerouslySetInnerHTML={{ __html: item.link }} />;
                      case 'imgdata':
                        return <Icons key={item.id}><img src={item[itemKey].url}
                                                         alt={item[itemKey].filename} /></Icons>;
                      default:
                        return null;
                    }
                  })}
                </StyledLinksHead>
              </>)

              : <h4>Wkrótce dostępne</h4>}
          </LinksHead>
          {!Fre.hidelinkdown ?
            (<>
              <StyledP isLeft>Katecheza nr:</StyledP>
              <LinksDown>
                {Fre.linkdown.map(item => (
                  <div key={item.id}>
                    <StyledP dangerouslySetInnerHTML={{ __html: item.linkd }} />
                  </div>
                ))}
              </LinksDown></>) : <><br/><div> <br/></div></> }
        </StyledDiv>
      ))}
    </StyleWrapper>
  );
};
Quides.propTypes = {
  category: PropTypes.string.isRequired

};
export default Quides;