import React from "react"
import MainTemplate from 'templates/MainTemplate'
import SEO from 'components/SEO/seo'
import Quides from 'components/FreeMaterials/Quides/Quides';
import {StyledFreeMaterials, StyleWrapper, StyledInner, StyledTitile } from 'components/FreeMaterials/FreeMaterials.styled'



const QuidesPage = () => (
<MainTemplate>
<SEO title="Poradniki metodyczne" />
    <StyledFreeMaterials>
        <StyleWrapper>

          <StyledTitile><h1><strong>Poradniki metodyczne</strong></h1></StyledTitile>
            <StyledInner>
              <Quides category="Przedszkole"/>
              <Quides category="Szkoła Podstawowa"/>
              <Quides category="Liceum/Technikum"/>
            </StyledInner>

        </StyleWrapper>
    </StyledFreeMaterials>

</MainTemplate>
)

export default QuidesPage
